<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="578px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}{{ viewType ? '设备' : '车辆' }}</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item v-if="!viewType" label="车牌号" prop="licenseNumber" class="w50 mr16">
				<el-input v-model.trim="ruleForm.licenseNumber" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item v-else label="设备名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item :label="!viewType ? '车型' : '设备类型'" prop="modelDictionaryItemId" :class="!viewType ? 'w50 mr0' : 'w50 mr16'">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.modelDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'modelDictionaryItemId')"
				>
					<el-option v-for="(item, index) in modelDictionaryList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="性质" prop="characterDictionaryItemId" :class="!viewType ? 'w50 mr16' : 'w50 mr0'">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.characterDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'characterDictionaryItemId')"
				>
					<el-option v-for="(item, index) in characterDictionaryList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="用途" prop="usageDictionaryItemId" :class="!viewType ? 'w50 mr0' : ''">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.usageDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'usageDictionaryItemId')"
				>
					<el-option v-for="(item, index) in usageDictionaryList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="!viewType" label="载人数" prop="carryingCapacity">
				<el-input v-model.trim="ruleForm.carryingCapacity" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="所属单位" prop="ownerOrganizationName">
				<el-input v-model.trim="ruleForm.ownerOrganizationName" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="principalName" label="负责人">
				<el-input v-model.trim="ruleForm.principalName" :disabled="isShowDetail" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="联系电话"></el-input>
			</el-form-item>
			<el-form-item label="上传图片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					:modulePath="!viewType ? 'VEHICLE' : 'EQUIPMENT'"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="4"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifyPhone } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			ruleForm: {
				licenseNumber: null,
				modelDictionaryItemId: null,
				characterDictionaryItemId: null,
				usageDictionaryItemId: null,
				name: null,
				carryingCapacity: '',
				ownerOrganizationName: '',
				principalName: '',
				phoneNumber: '',
				images: [],
			},
			modelDictionaryList: [], //车型
			characterDictionaryList: [], //性质
			usageDictionaryList: [], //用途
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				licenseNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
				ownerOrganizationName: [{ required: true, message: '请输入', trigger: 'blur' }],
				modelDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				characterDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				usageDictionaryItemId: [{ required: true, message: '请选择', trigger: 'blur' }],
				carryingCapacity: [{ required: true, message: '请输入', trigger: 'blur' }],
				principalName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				images: [{ required: true, message: '请上传图片', trigger: 'blur' }],
			},
			saveLoading: false,
			attachmentInfo: {},
			link: '',
		};
	},
	props: {
		isShowDetail: {
			type: Boolean,
			default: false,
		},
		viewType: {
			type: String,
			default: '',
		},
	},
	watch: {
		viewType: {
			handler(val) {
				if (val) {
					this.$nextTick(() => {
						this.rules = this.rules.map((item) => {
							if (item.prop == 'licenseNumber') {
								item.required = false;
							}
							if (item.prop == 'name') {
								item.required = true;
							}
							if (item.prop == 'carryingCapacity') {
								item.required = false;
							}
							return item;
						});
					});
				} else {
					this.$nextTick(() => {
						this.rules = this.rules.map((item) => {
							if (item.prop == 'licenseNumber') {
								item.required = true;
							}
							if (item.prop == 'name') {
								item.required = false;
							}
							if (item.prop == 'carryingCapacity') {
								item.required = true;
							}
							return item;
						});
					});
				}
			},
		},
	},
	created() {},
	methods: {
		init(parkInfo) {
			this.createdVisible = true;
			this.getAttachment();
			this.getCharacterList();
			this.getCarTypeList();
			this.getCarUseList();
			this.link = parkInfo.link;
			if (parkInfo.row) {
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
					this.setRuleForm(parkInfo.row);
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取类型list
		getCarTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, {
					params: { dictionaryCode: this.viewType ? 'EQUIPMENT_TYPE' : 'MOTORCYCLE_TYPE', size: 9999, current: 1 },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.modelDictionaryList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取用途
		getCarUseList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, {
					params: { dictionaryCode: this.viewType ? 'EQUIPMENT_USAGE' : 'VEHICLE_USAGE', size: 9999, current: 1 },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.usageDictionaryList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取性质
		getCharacterList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, {
					params: { dictionaryCode: this.viewType ? 'EQUIPMENT_PROPERTY' : 'VEHICLE_PROPERTY', size: 9999, current: 1 },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.characterDictionaryList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachment() {
			let code = this.viewType ? 'EQUIPMENT' : 'VEHICLE';
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 设置表单数据
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.modelDictionaryItemId = data.modelDictionaryItem?.id || null;
			this.ruleForm.characterDictionaryItemId = data.characterDictionaryItem?.id || null;
			this.ruleForm.usageDictionaryItemId = data.usageDictionaryItem?.id || null;
			this.ruleForm.images = data.attachmentDtoMap['DETAILPHOTO'].map((item) => {
				return {
					url: item.link,
					uid: item.id,
					title: item.title,
				};
			});
		},
		// 保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						attachments: this.ruleForm.images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DETAILPHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DETAILPHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$refs.uploadImage.images = [];
			this.$refs[formName].resetFields();
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}

/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .w40 {
	width: calc(50% - 14px);
	display: inline-block;
}
/deep/ .m28 {
	margin-right: 28px;
}
</style>